import { SignUp } from "@clerk/clerk-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/sign-up")({
  component: SignUpComponent,
});

function SignUpComponent() {
  return (
    <div className="flex items-center justify-center py-4">
      <SignUp path="/sign-up" routing="hash" signInUrl="/sign-in" />
    </div>
  );
}
