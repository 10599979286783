import type { Score } from "@/types/addresses";
import type { GetToken } from "@/types/api";
import * as formatters from "./formatters";

import { http } from "..";
import type {
  Address,
  Dashboard,
  ExportAddressesCSV,
  NewDashboard,
  ScoreById,
} from "./types";

export const getScores = async (getToken: GetToken): Promise<Score[]> => {
  const token = await getToken();

  return http.url("admin/scores").auth(`Bearer ${token}`).get().json();
};

export const getScoreById = async (
  id: string,
  getToken: GetToken,
): Promise<ScoreById> => {
  const token = await getToken();

  return http.url(`admin/score/${id}`).auth(`Bearer ${token}`).get().json();
};

export const postAddress = async (body: Address, getToken: GetToken) => {
  const token = await getToken();

  return http
    .url("admin/onboard/address")
    .auth(`Bearer ${token}`)
    .post(body)
    .json();
};

export const postAddressesCSV = async (file: File, getToken: GetToken) => {
  const token = await getToken();

  return http
    .url("admin/onboard/addresses")
    .auth(`Bearer ${token}`)
    .formData({ file, param: "file" })
    .post()
    .json();
};

export const exportAddressesCSV = async (
  getToken: GetToken,
): Promise<ExportAddressesCSV> => {
  const token = await getToken();

  return http.url("admin/scores/report").auth(`Bearer ${token}`).get().json();
};

export const getDashboard = async (
  getToken: GetToken,
): Promise<NewDashboard> => {
  const token = await getToken();

  return http
    .url("admin/dashboard")
    .auth(`Bearer ${token}`)
    .get()
    .json()
    .then((data) => formatters.dashboard(data as Dashboard));
};
