import type { EPCData } from "@/api/scoring/types";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { calculatingValuesPropertyStatistics } from "@/utils/calculating-values-property-statistics";

type Props = {
  info: string;
  name: string;
  value: number | string;
};

const Card = ({ info, name, value }: Props) => {
  return (
    <div className="p-6 flex flex-col justify-between text-lg font-medium">
      <div className="flex flex-row items-center justify-between space-y-0 mb-8">
        <p>{name}</p>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="ghost"
                size="icon"
                className="rounded-xl border-black border w-5 h-5"
              >
                i
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{info}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="text-3xl font-bold">{value}</div>
    </div>
  );
};

const PropertyStatistics = ({ epc }: { epc: EPCData }) => {
  const {
    consumptionM2,
    monthlyConsumption,
    annualConsumption,
    monthlyBill,
    annualBill,
  } = calculatingValuesPropertyStatistics(epc);

  return (
    <div className="grid grid-cols-4 rounded-lg border bg-white">
      <div className="col-span-1 border-r border-b border-1">
        <Card
          info="Current energy consumption according to the EPC certificate"
          name="Energy Consumption per m2"
          value={`${consumptionM2} kWh/m2`}
        />
      </div>
      <div className="col-span-1 border-r border-b border-1">
        <Card
          info="Current energy consumption according to the EPC certificate"
          value={`${monthlyConsumption} kWt/month`}
          name="Monthly Energy Consumption"
        />
      </div>
      <div className="col-span-1 border-r border-b border-1">
        <Card
          name="Annual Energy Consumption"
          info="Annual electricity consumption calculated based on data from EPC certificate"
          value={`${annualConsumption} kWt/year`}
        />
      </div>
      <div className="col-span-1 border-b border-1">
        <Card
          name="Monthly Gas Emission"
          value={`${epc.CO2_EMISSIONS_CURRENT} kg CO2`}
          info="The amount of greenhouse gas emissions indicated in the EPC certificate"
        />
      </div>
      <div className="col-span-2 border-r border-1">
        <Card
          name="Monthly Energy Bill"
          info="Monthly energy bill calculated based on the average tariff"
          value={`£ ${monthlyBill}`}
        />
      </div>
      <div className="col-span-2">
        <Card
          name="Annual Energy Bill"
          info="Annual energy bill calculated based on the average tariff"
          value={`£ ${annualBill}`}
        />
      </div>
    </div>
  );
};

export default PropertyStatistics;
