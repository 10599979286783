import type { StringToStringMap } from "@/types/portfolio-analysis";

export const RATINGS: StringToStringMap = {
  A: "excellent",
  B: "very good",
  C: "good",
  D: "average",
  E: "below the average",
  F: "poor",
  G: "very poor",
};
