import { useAuth, useSignIn, useUser } from "@clerk/clerk-react";
import { useEffect } from "react";

export function SyncActiveOrganization() {
  const { isLoaded: isLoadedUser, user } = useUser();
  const { isLoaded, setActive } = useSignIn();
  const { isLoaded: isLoadedAuth, orgId } = useAuth();

  useEffect(() => {
    if (!isLoaded || !isLoadedUser || !isLoadedAuth) return;

    if (!orgId) {
      setActive({
        organization: user?.organizationMemberships[0]?.organization.id,
      });
    }
  }, [isLoaded, isLoadedUser, isLoadedAuth, setActive, user, orgId]);

  return null;
}
