import { VerticalBar } from "@/components/charts/Bars";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { config } from "@/configs/investment-profit";
import type { NewValueExplanationData } from "@/types/portfolio-analysis";
import { sixMaxValues } from "@/utils/six-max-values";
import getValueWithSymbol from "../-helpers/getValueWithSymbol";

type Props = {
  data: NewValueExplanationData;
};

const ValueExplanationCharts = ({ data }: Props) => {
  return (
    <>
      {data ? (
        <div className="grid grid-cols-2 rounded-xl	border bg-white divide-x">
          <div className="p-6 col-span-1">
            <Tabs defaultValue="estimates">
              <TabsList className="grid w-full grid-cols-2 bg-background rounded-xl border mb-6">
                <TabsTrigger
                  value="estimates"
                  className="data-[state=active]:bg-black data-[state=active]:text-white rounded-xl"
                >
                  Retrofit by Type
                </TabsTrigger>
                <TabsTrigger
                  value="implement"
                  className="data-[state=active]:bg-black data-[state=active]:text-white rounded-xl"
                >
                  Cost to Upgrade
                </TabsTrigger>
              </TabsList>
              <TabsContent value="estimates">
                <VerticalBar
                  data={sixMaxValues(data.recommendationsBar)}
                  barName="Number of dwellings"
                />
              </TabsContent>
              <TabsContent value="implement">
                <VerticalBar
                  data={sixMaxValues(data.marketVolume)}
                  barName="Cost of implementation £"
                />
              </TabsContent>
            </Tabs>
          </div>
          <div className="p-6 col-span-1">
            <p className="font-semibold text-sm mb-5">
              Install solar photovoltaic panels
            </p>
            <div className="grid grid-rows divide-y">
              {data.investmentProfit.map((item) => {
                const newValue = getValueWithSymbol(item);
                return (
                  <div className="grid grid-cols-2 py-5" key={item.name}>
                    <div className="col-span-1 font-medium text-xs">
                      {config[item.name]}
                    </div>
                    <div className="col-span-1 text-sm font-semibold">
                      {newValue}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        "The loaded addresses lack dwellings in category this category. Please consider other categories or upload more addresses for this category."
      )}
    </>
  );
};

export default ValueExplanationCharts;
