import type { ArrayWithNumberAndStringProperties } from "@/types/portfolio-analysis";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type Props = {
  data: ArrayWithNumberAndStringProperties;
  barName: string;
  label: string;
};

const formatLabel = (value: string) => {
  if (value === "less2years") {
    return "<2";
  }

  if (value === "more10year") {
    return "10+";
  }

  return value;
};

function Horizontal({ data, barName, label }: Props) {
  return (
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={data}>
          <CartesianGrid vertical={false} strokeDasharray="10" />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickFormatter={(value) => formatLabel(value)}
          />
          <YAxis yAxisId="value" orientation="left" axisLine={false} />
          <Tooltip
            labelFormatter={(value) => `${label}: ${formatLabel(value)}`}
            itemStyle={{ color: "black" }}
            cursor={{ fill: "none" }}
          />
          <Bar
            yAxisId="value"
            dataKey="value"
            name={barName}
            fill="#E2E8F0"
            radius={[10, 10, 0, 0]}
            activeBar={<Rectangle fill="#E9FF99" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Horizontal;
