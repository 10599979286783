import { AuthenticatedLayout } from "@/components/layouts";
import { SyncActiveOrganization } from "@/utils/sync-active-organization";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isSignedIn) {
      throw redirect({
        to: "/sign-in",
        search: {
          redirect: location.href,
        },
      });
    }
    if (!context.organization.hasOrganization) {
      throw redirect({
        to: "/create-organization",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: AuthenticatedPage,
});

function AuthenticatedPage() {
  return (
    <>
      <SyncActiveOrganization />
      <AuthenticatedLayout />
    </>
  );
}
