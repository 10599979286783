import type { StringToStringMap } from "@/types/portfolio-analysis";

export const config: StringToStringMap = {
  investments: "Cost to Upgrade",
  improvement: "Upgrade Type",
  dwellingsNumber: "Number of Proerties to which Upgrade is Applied",
  paybackYears: "Payback",
  averageMarketPrice: "Average Property Market Price",
  marketPriceGrowth: "Impact on the Portfolio",
  mortgagePortfolioGrowth: "Net Positive Impact - Portfolio level",
};
