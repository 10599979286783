import { config } from "@/configs/investment-profit";
import type { InvestmentProfit } from "@/types/portfolio-analysis";
import getValueWithSymbol from "../-helpers/getValueWithSymbol";

type Props = {
  data: InvestmentProfit[];
};

const TotalInvestmentProfit = ({ data }: Props) => {
  return (
    <div>
      <h1 className="font-semibold text-2xl mb-5">Aggregated view</h1>
      <div className="grid grid-cols-7 rounded-xl	border bg-white divide-x mb-4">
        {data.map((item) => {
          const newValue = getValueWithSymbol(item);

          return (
            <div
              className="col-span-1 p-6 flex flex-col justify-between text-3xl font-bold"
              key={item.name}
            >
              <div className="flex flex-row items-center justify-between space-y-0 mb-8">
                <p className="text-sm font-medium">{config[item.name]}</p>
              </div>
              <div className="text-lg font-bold">{newValue}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TotalInvestmentProfit;
