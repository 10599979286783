import type { GetToken } from "@/types/api";
import type { CreateKey, Key } from "@/types/api-keys";
import { http2 } from "..";

export const postKey = async (
  body: CreateKey,
  getToken: GetToken,
): Promise<Key> => {
  const token = await getToken();

  return http2.url("v1/create").auth(`Bearer ${token}`).post(body).json();
};

export const getKeys = async (getToken: GetToken): Promise<Key[]> => {
  const token = await getToken();

  return http2.url("v1/list").auth(`Bearer ${token}`).get().json();
};

export const getKeyById = async (
  id: string,
  getToken: GetToken,
): Promise<Key> => {
  const token = await getToken();

  return http2.url(`v1/get/${id}`).auth(`Bearer ${token}`).get().json();
};

export const removeKey = async (id: string, getToken: GetToken) => {
  const token = await getToken();

  return http2.url(`v1/${id}`).auth(`Bearer ${token}`).delete().json();
};
