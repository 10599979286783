import wretch from "wretch";
import FormDataAddon from "wretch/addons/formData";

export const http = wretch(import.meta.env.VITE_BASE_API_URL).addon(
  FormDataAddon,
);

export const http2 = wretch(import.meta.env.VITE_BASE_API_URL_KEYS).addon(
  FormDataAddon,
);
