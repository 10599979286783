import { CreateOrganization } from "@clerk/clerk-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/create-organization")({
  component: CreateComponent,
});

function CreateComponent() {
  return (
    <div className="flex items-center justify-center py-4">
      <CreateOrganization
        path="/organization/create"
        routing="hash"
        afterCreateOrganizationUrl="/addresses"
      />
    </div>
  );
}
