import { ADMIN, ALL } from "@/constants/roles";
import { BarChart, Home, KeyRound, Landmark, UserRound } from "lucide-react";
import type { ReactElement } from "react";

type Type = {
  label: string;
  to: string;
  icon: ReactElement;
  role: string;
};

export const NAV_ITEMS: Type[] = [
  {
    label: "Quantified Homes",
    to: "/addresses",
    icon: <Home className="h-4 w-4" />,
    role: ALL,
  },
  {
    label: "Portfolio Insights",
    to: "/portfolio-analysis",
    icon: <BarChart className="h-4 w-4" />,
    role: ALL,
  },
  {
    label: "Organization profile",
    to: "/organization-profile",
    icon: <Landmark className="h-4 w-4" />,
    role: ADMIN,
  },
  {
    label: "API keys",
    to: "/api-keys",
    icon: <KeyRound className="h-4 w-4" />,
    role: ADMIN,
  },
  {
    label: "User profile",
    to: "/user-profile",
    icon: <UserRound className="h-4 w-4" />,
    role: ALL,
  },
];
