import type {
  ArrayWithNumberAndStringProperties,
  InvestmentProfit,
  KPIData,
  NewValueExplanationCharts,
} from "@/types/portfolio-analysis";

export type Address = {
  route: string;
  street_number: string;
  postal_town: string;
  postal_code: string;
};

export type EnergyEfficiency =
  | "Very Good"
  | "Good"
  | "Average"
  | "Poor"
  | "Very Poor"
  | "N/A";

export type EPCPropertyType =
  | "bungalow"
  | "flat"
  | "house"
  | "maisonette"
  | "parkhome";

export enum Statuses {
  PENDING = "PENDING",
  CALCULATED = "CALCULATED",
  FAILED = "FAILED",
}

export type EPCData = {
  LMK_KEY: string;
  ADDRESS1: string;
  ADDRESS2: string;
  ADDRESS3: string;
  POSTCODE: string;
  BUILDING_REFERENCE_NUMBER: string;
  CURRENT_ENERGY_RATING: string;
  POTENTIAL_ENERGY_RATING: string;
  CURRENT_ENERGY_EFFICIENCY: string;
  POTENTIAL_ENERGY_EFFICIENCY: string;
  PROPERTY_TYPE: EPCPropertyType;
  BUILT_FORM: string;
  INSPECTION_DATE: string;
  LOCAL_AUTHORITY: string;
  CONSTITUENCY: string;
  COUNTY: string;
  LODGEMENT_DATE: string;
  TRANSACTION_TYPE: string;
  ENVIRONMENT_IMPACT_CURRENT: string;
  ENVIRONMENT_IMPACT_POTENTIAL: string;
  ENERGY_CONSUMPTION_CURRENT: string;
  ENERGY_CONSUMPTION_POTENTIAL: string;
  CO2_EMISSIONS_CURRENT: string;
  CO2_EMISS_CURR_PER_FLOOR_AREA: string;
  CO2_EMISSIONS_POTENTIAL: string;
  LIGHTING_COST_CURRENT: string;
  LIGHTING_COST_POTENTIAL: string;
  HEATING_COST_CURRENT: string;
  HEATING_COST_POTENTIAL: string;
  HOT_WATER_COST_CURRENT: string;
  HOT_WATER_COST_POTENTIAL: string;
  TOTAL_FLOOR_AREA: string;
  ENERGY_TARIFF: string;
  MAINS_GAS_FLAG: string;
  FLOOR_LEVEL: string;
  FLAT_TOP_STOREY: string;
  FLAT_STOREY_COUNT: string;
  MAIN_HEATING_CONTROLS: string;
  MULTI_GLAZE_PROPORTION: string;
  GLAZED_TYPE: string;
  GLAZED_AREA: string;
  EXTENSION_COUNT: string;
  NUMBER_HABITABLE_ROOMS: string;
  NUMBER_HEATED_ROOMS: string;
  LOW_ENERGY_LIGHTING: string;
  NUMBER_OPEN_FIREPLACES: string;
  HOTWATER_DESCRIPTION: string;
  HOT_WATER_ENERGY_EFF: EnergyEfficiency;
  HOT_WATER_ENV_EFF: string;
  FLOOR_DESCRIPTION: string;
  FLOOR_ENERGY_EFF: EnergyEfficiency;
  FLOOR_ENV_EFF: string;
  WINDOWS_DESCRIPTION: string;
  WINDOWS_ENERGY_EFF: EnergyEfficiency;
  WINDOWS_ENV_EFF: string;
  WALLS_DESCRIPTION: string;
  WALLS_ENERGY_EFF: EnergyEfficiency;
  WALLS_ENV_EFF: string;
  SECONDHEAT_DESCRIPTION: string;
  SHEATING_ENERGY_EFF: EnergyEfficiency;
  SHEATING_ENV_EFF: string;
  ROOF_DESCRIPTION: string;
  ROOF_ENERGY_EFF: EnergyEfficiency;
  ROOF_ENV_EFF: string;
  MAINHEAT_DESCRIPTION: string;
  MAINHEAT_ENERGY_EFF: EnergyEfficiency;
  MAINHEAT_ENV_EFF: string;
  MAINHEATCONT_DESCRIPTION: string;
  MAINHEATC_ENERGY_EFF: EnergyEfficiency;
  MAINHEATC_ENV_EFF: string;
  LIGHTING_DESCRIPTION: string;
  LIGHTING_ENERGY_EFF: EnergyEfficiency;
  LIGHTING_ENV_EFF: string;
  MAIN_FUEL: string;
  WIND_TURBINE_COUNT: string;
  HEAT_LOSS_CORRIDOR: string;
  UNHEATED_CORRIDOR_LENGTH: string;
  FLOOR_HEIGHT: string;
  PHOTO_SUPPLY: string;
  SOLAR_WATER_HEATING_FLAG: string;
  MECHANICAL_VENTILATION: string;
  ADDRESS: string;
  LOCAL_AUTHORITY_LABEL: string;
  CONSTITUENCY_LABEL: string;
  POSTTOWN: string;
  CONSTRUCTION_AGE_BAND: string;
  LODGEMENT_DATETIME: string;
  TENURE: string;
  FIXED_LIGHTING_OUTLETS_COUNT: string;
  LOW_ENERGY_FIXED_LIGHT_COUNT: string;
  UPRN: string;
  UPRN_SOURCE: string;
};

export type Recommendations = {
  id: string;
  index: number;
  recommendation_short: string;
  recommendation_detailed: string;
  saving_per_year: number;
  indicative_cost: string;
};

export type ScoreById = {
  id: string;
  userId: string;
  organizationId: string;
  apiPlusLink: string;
  address: string;
  postcode: string;
  epcData: EPCData;
  status: Statuses;
  recommendations: Recommendations[];
  recommendationsWeights: number[];
  score: number;
  energyConsumptionScore: number;
  energyEfficiencyScore: number;
  energyReusableScore: number;
};

export type ExportAddressesCSV = {
  downloadURL: string;
};

export type RatingBarChart = {
  A: number;
  B: number;
  C: number;
  D: number;
  E: number;
  F: number;
  G: number;
  unknown: number;
};

export type CertificateAgeChart = {
  less1year: number;
  "1-3": number;
  "4-6": number;
  "6-8": number;
  "9-10": number;
  more10year: number;
};

export type RecommendationsList = {
  index: number;
  recommendation_short: string;
};

export type StringToNumberMap = {
  [key: string]: number;
};

export type ValueExplanationData = {
  marketVolume: StringToNumberMap;
  recommendationBar: StringToNumberMap;
  improvement: string;
  dwellingsNumber: number;
  investments: number;
  paybackYears: number;
  averageMarketPrice: number;
  marketPriceGrowth: number;
  mortgagePortfolioGrowth: number;
};

export type ValueExplanationCharts = {
  C: ValueExplanationData;
  D: ValueExplanationData;
  E: ValueExplanationData;
  F: ValueExplanationData;
  G: ValueExplanationData;
};

export type Dashboard = {
  kpi: KPIData;
  ratingBar: RatingBarChart;
  certificateAgeChart: CertificateAgeChart;
  recommendationsList: RecommendationsList[];
  valueExplanationCharts: ValueExplanationCharts;
};

export type NewDashboard = {
  kpi: KPIData;
  ratingBar: ArrayWithNumberAndStringProperties;
  certificateAgeChart: ArrayWithNumberAndStringProperties;
  recommendationsList: RecommendationsList[];
  valueExplanationCharts: NewValueExplanationCharts;
  totalInvestmentProfit: InvestmentProfit[];
};
