import { usePostAddress } from "@/api/scoring/queries";
import type { Address } from "@/api/scoring/types";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Plus } from "lucide-react";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

export function AddAddressDialog() {
  const [open, setOpen] = useState(false);

  const handleInteractOutside = (e: Event) => {
    const classes: Array<Array<string>> = [];
    const path = e.composedPath();

    for (const el of path) {
      if ((el as Element).classList) {
        classes.push(Array.from((el as Element).classList));
      }
    }

    if (classes.join("-").includes("pac-container")) {
      // pac = classes naming for https://www.npmjs.com/package/react-google-autocomplete
      // primary use case is the "AddContact" modal
      e.preventDefault();
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="px-4 py-3">
          <Plus className="mr-2 h-[18px] w-[18px]" />
          Add address
        </Button>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px]"
        onInteractOutside={handleInteractOutside}
      >
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">
            Add new address
          </DialogTitle>
        </DialogHeader>
        <AddressForm onClose={setOpen} />
      </DialogContent>
    </Dialog>
  );
}

function AddressForm({
  onClose,
}: { onClose: Dispatch<SetStateAction<boolean>> }) {
  const { mutateAsync, isPending } = usePostAddress();
  const [address, setAddress] = useState<Address>();
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    language: "en",
    options: {
      types: ["street_address"],
      fields: ["address_components"],
      componentRestrictions: { country: "uk" },
    },
    onPlaceSelected: (place) => {
      const keys = ["route", "street_number", "postal_town", "postal_code"];

      const body = keys.reduce((obj, key) => {
        for (const address of place.address_components) {
          if (address.types.some((type: string) => key === type)) {
            /* @ts-ignore */
            obj[key] = address?.long_name;
          }
        }

        return obj;
      }, {});

      setAddress(body as Address);
    },
  });

  const submit = () => {
    if (address) {
      mutateAsync(address);
    }
    onClose(false);
  };

  useEffect(() => {
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    setTimeout(() => (document.body.style.pointerEvents = ""), 0);
  });

  return (
    <div className={cn("grid items-start gap-4")}>
      <div className="grid gap-2">
        <Input
          placeholder="Example: 11, Woodlea, Leybourne, CR0 1PB"
          ref={ref}
        />
      </div>
      <Button onClick={submit} type="submit" disabled={!address || isPending}>
        Submit
      </Button>
    </div>
  );
}
