import { UserProfile } from "@clerk/clerk-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/user-profile/")({
  component: UserProfileComponent,
});

function UserProfileComponent() {
  return <UserProfile path="/user-profile" routing="hash" />;
}
