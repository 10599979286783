import type { EPCData } from "@/api/scoring/types";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { RATINGS } from "@/configs/ratings";
import { COLORS } from "@/constants/colors-rating";
import { getYear } from "date-fns";

const EstimateFromEPC = ({ epc }: { epc: EPCData }) => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="rounded-lg border grid grid-cols-3 divide-x bg-white h-full">
      <div className="p-6 flex flex-col justify-between text-lg font-medium">
        <div className="flex flex-row items-center justify-between space-y-0 pb-2">
          <p>EPC Rating</p>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-xl border-black border w-5 h-5"
                >
                  i
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  The current energy efficiency rating assigned during the last
                  home certification
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-end">
          <div
            className={`${
              COLORS[epc.CURRENT_ENERGY_RATING]
            } pl-2 w-20 rounded-md text-3xl font-bold mr-1.5`}
          >
            {epc.CURRENT_ENERGY_RATING}
          </div>
          <span className="font-medium text-lg">
            /{RATINGS[epc.CURRENT_ENERGY_RATING]}
          </span>
        </div>
      </div>
      <div className="p-6 flex flex-col justify-between text-lg font-medium">
        <div className="flex flex-row items-center justify-between space-y-0 pb-2">
          <p>Potential Rating</p>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-xl border-black border w-5 h-5"
                >
                  i
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  Energy efficiency rating that can be assigned when
                  implementing recommendations
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-end">
          <div
            className={`${
              COLORS[epc.POTENTIAL_ENERGY_RATING]
            } pl-2 w-20 rounded-md text-3xl font-bold mr-1.5`}
          >
            {epc.POTENTIAL_ENERGY_RATING}
          </div>
          <span className="font-medium text-lg">
            /{RATINGS[epc.POTENTIAL_ENERGY_RATING]}
          </span>
        </div>
      </div>
      <div className="p-6 flex flex-col justify-between text-lg font-medium">
        <div className="flex flex-row items-center justify-between space-y-0 pb-2">
          <p>EPC Report Age</p>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-xl border-black border w-5 h-5"
                >
                  i
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Time that has passed since receiving the certificate</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="pl-2 w-28 rounded-md text-3xl font-bold flex items-center bg-input">
          <span className="mr-2">{year - getYear(epc.INSPECTION_DATE)}</span>
          <span className="font-medium text-lg">year(s)</span>
        </div>
      </div>
    </div>
  );
};

export default EstimateFromEPC;
