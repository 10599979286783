import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";

interface MyRouterContext {
  auth: { isSignedIn: boolean | undefined };
  organization: { hasOrganization: boolean | undefined };
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  return <Outlet />;
}
