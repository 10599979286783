import { usePostKey } from "@/api/api-keys/queries";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { type Dispatch, type SetStateAction, useState } from "react";

export function AddKeyDialog() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Key generation</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Description key</DialogTitle>
          <DialogDescription>Click save when you're done.</DialogDescription>
        </DialogHeader>
        <KeyForm onClose={setOpen} />
      </DialogContent>
    </Dialog>
  );
}

function KeyForm({ onClose }: { onClose: Dispatch<SetStateAction<boolean>> }) {
  const { mutateAsync } = usePostKey(() => onClose(false));
  const [description, setDescription] = useState<string>();

  const submit = () => {
    if (description) {
      mutateAsync({ description: description });
    }
  };

  return (
    <div className={cn("grid items-start gap-4")}>
      <div className="grid gap-2">
        <Textarea
          placeholder="Add description."
          onChange={(e) => setDescription(e.currentTarget.value)}
        />
      </div>
      <Button onClick={submit} type="submit" disabled={!description}>
        Submit
      </Button>
    </div>
  );
}
