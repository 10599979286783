import type { NewDashboard } from "@/api/scoring/types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RATINGS_TABS } from "@/configs/explanation-charts";
import ValueExplanationCharts from "./value-explanation-charts";

type Props = {
  data: NewDashboard;
};

function PortfolioGrowthOpportunities({ data }: Props) {
  const listRating = Object.entries(RATINGS_TABS);

  return (
    <div className="mb-5 flex justify-between items-center">
      <Tabs defaultValue={listRating[0][0]} className="w-full">
        <div className="flex justify-between">
          <h1 className="font-semibold text-2xl">Segmented View</h1>
          <TabsList
            className={`grid grid-cols-${listRating.length} bg-background rounded-xl border`}
          >
            {listRating.map((item) => (
              <TabsTrigger
                key={item[0]}
                value={item[0]}
                className="data-[state=active]:bg-black data-[state=active]:text-white rounded-xl"
              >
                {item[1]}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
        {listRating.map((item) => (
          <TabsContent value={item[0]} key={item[0]}>
            <ValueExplanationCharts
              // @ts-ignore: Unreachable code error
              data={data.valueExplanationCharts[item[0]]}
            />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}

export default PortfolioGrowthOpportunities;
