import { CalendarPlus } from "lucide-react";
import { AddAddressDialog } from "./add-address-dialog";
import { UploadAddressesDialog } from "./upload-csv-dialog";

function EmptyState() {
  return (
    <div className="place-self-center flex flex-col justify-center items-center">
      <div className="h-28 w-28 bg-scoreCard-background rounded-full mb-4 flex justify-center items-center">
        <CalendarPlus className="h-12 w-12" />
      </div>
      <h1 className="mb-4 text-2xl font-semibold text-activeNav-color">
        Upload at least 10 addresses to get started
      </h1>
      <p className="mb-8 font-medium text-lg text-activeNav-color/50">
        And we will prepare your mortgage portfolio.
      </p>
      <div className="flex justify-around">
        <div className="mr-5">
          <AddAddressDialog />
        </div>
        <UploadAddressesDialog />
      </div>
    </div>
  );
}

export default EmptyState;
