import type { CreateKey } from "@/types/api-keys";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getKeyById, getKeys, postKey, removeKey } from "./";

export const useGetKeys = () => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["getKeys"],
    queryFn: () => getKeys(getToken),
  });
};

export const useGetKeyById = ({ id }: { id: string }) => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["getKeyById", id],
    queryFn: () => getKeyById(id, getToken),
  });
};

export const usePostKey = (callback: () => void) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["postKey"],
    mutationFn: (body: CreateKey) => postKey(body, getToken),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKeys"] });
      callback();
    },
  });
};
export const useRemoveKey = (callback: () => void) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["removeKey"],
    mutationFn: (id: string) => removeKey(id, getToken),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKeys"] });
      callback();
    },
  });
};
