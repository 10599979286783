import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { toast } from "@/components/ui/use-toast";
import type { WretchError } from "wretch/types";
import {
  exportAddressesCSV,
  getDashboard,
  getScoreById,
  getScores,
  postAddress,
  postAddressesCSV,
} from "./";
import type { Address } from "./types";

export const useGetScores = () => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["getScores"],
    queryFn: () => getScores(getToken),
  });
};

export const useGetScoreById = ({ id }: { id: string }) => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["getScoreById", id],
    queryFn: () => getScoreById(id, getToken),
  });
};

export const usePostAddress = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["postAddress"],
    mutationFn: (body: Address) => postAddress(body, getToken),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getScores"] });
      toast({
        description: "New address added",
      });
    },
    onError: (error: WretchError) => {
      toast({
        variant: "destructive",
        description: error.json?.message,
      });
    },
  });
};

export const usePostAddressesCSV = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["postAddressesCSV"],
    mutationFn: (file: File) => postAddressesCSV(file, getToken),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getScores"] });
      toast({
        description: "New addresses added",
      });
    },
    onError: (error: WretchError) => {
      toast({
        variant: "destructive",
        description: error.json?.message,
      });
    },
  });
};

export const useExportAddressesCSV = () => {
  const { getToken } = useAuth();

  return useMutation({
    mutationKey: ["exportAddressesCSV"],
    mutationFn: () => exportAddressesCSV(getToken),
    onSuccess: (resp) => {
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${resp.downloadURL}`;
      link.click();
    },
  });
};

export const useGetDashboard = () => {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["getDashboard"],
    queryFn: () => getDashboard(getToken),
  });
};
