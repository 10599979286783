import { useExportAddressesCSV, useGetScores } from "@/api/scoring/queries";
import { Pagination, Table } from "@/components/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { createFileRoute } from "@tanstack/react-router";
import {
  type ColumnFiltersState,
  type SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FileDown } from "lucide-react";
import { useState } from "react";
import { AddAddressDialog } from "./-components/add-address-dialog";
import { columns } from "./-components/columns";
import EmptyState from "./-components/empty-state";
import { UploadAddressesDialog } from "./-components/upload-csv-dialog";

export const Route = createFileRoute("/_authenticated/addresses/")({
  component: AddressesComponent,
});

function AddressesComponent() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { mutateAsync: exportAddressesCSV } = useExportAddressesCSV();

  const { data } = useGetScores();
  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  const onExportCsv = () => {
    exportAddressesCSV();
  };

  if (!data) {
    return;
  }

  return (
    <>
      {data?.length > 0 ? (
        <div className="w-full grid grid-rows-[auto 1fr]">
          <div className="flex items-center justify-between text-2xl font-semibold text-activeNav-color h-[40px]">
            <h1>Addresses({data?.length})</h1>
            <Button
              onClick={onExportCsv}
              className="px-4 py-3  bg-activeNav-color text-white"
              variant="outline"
              type="submit"
              disabled={data?.length === 0}
            >
              <FileDown className="mr-2 h-[18px] w-[18px]" />
              Export CSV
            </Button>
          </div>
          <div className="flex justify-between items-center gap-4 py-4">
            <Input
              placeholder="Filter address..."
              value={
                (table.getColumn("address")?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                table.getColumn("address")?.setFilterValue(event.target.value)
              }
              className="max-w-sm"
            />
            <div className="flex">
              <div className="mr-5">
                <UploadAddressesDialog />
              </div>
              <AddAddressDialog />
            </div>
          </div>
          {data && (
            <>
              <Table columns={columns} table={table} />
              <Pagination table={table} />
            </>
          )}
        </div>
      ) : (
        <div className="w-full grid grid-rows-[auto 1fr] min-h-[calc(100vh-238px)]">
          <div className="flex items-center justify-between text-2xl font-semibold text-activeNav-color h-[40px]">
            <h1>Addresses({data?.length})</h1>
            <Button
              onClick={onExportCsv}
              className="px-4 py-3  bg-activeNav-color text-white"
              variant="outline"
              type="submit"
              disabled={data?.length === 0}
            >
              <FileDown className="mr-2 h-[18px] w-[18px]" />
              Export CSV
            </Button>
          </div>
          <EmptyState />
        </div>
      )}
    </>
  );
}
