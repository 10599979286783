import { usePostAddressesCSV } from "@/api/scoring/queries";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { FileUp, Paperclip } from "lucide-react";
import { FileDown } from "lucide-react";
import { type Dispatch, type SetStateAction, useState } from "react";
import { type Accept, useDropzone } from "react-dropzone";

export function UploadAddressesDialog() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="px-4 py-3">
          <FileUp className="mr-2 h-[18px] w-[18px]" />
          Upload CSV
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">
            Upload CSV
          </DialogTitle>
        </DialogHeader>
        <Form onClose={setOpen} />
      </DialogContent>
    </Dialog>
  );
}

function Form({ onClose }: { onClose: Dispatch<SetStateAction<boolean>> }) {
  const [files, setFiles] = useState<File>();
  const { mutateAsync, isPending } = usePostAddressesCSV();

  const onSubmit = async () => {
    try {
      if (files) {
        await mutateAsync(files);
      }
      onClose(false);
    } catch (error) {
      // TODO: add toast and remove in usePostAddressesCSV
      console.log(error);
    }
  };

  const UPLOAD_TYPES: Accept = {
    "text/csv": [".csv"],
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted(file) {
      setFiles(file[0]);
    },
    onDropRejected() {
      toast({
        description: "Files rejected. Please, check your files",
      });
    },
    onError: (err) => {
      console.log(err);
    },
    accept: UPLOAD_TYPES,
    multiple: false,
  });

  return (
    <div className="grid grid-cols-2">
      <div className="mr-5">
        <div className="mb-5 flex font-semibold items-center">
          <span className="mr-3 w-9 h-9 bg-activeNav-color text-white rounded-full flex justify-center items-center text-base">
            1
          </span>
          <h1 className="text-xl">Create your .CSV File</h1>
        </div>
        <div className="py-4 px-6 rounded-2xl bg-slate-200/30 flex items-center text-lg mb-2">
          <span className="mr-3 w-9 h-9 bg-white rounded-full flex justify-center items-center font-semibold">
            1
          </span>
          <h2 className="font-medium">Collect Address Data</h2>
        </div>
        <div className="py-4 px-6 rounded-2xl bg-slate-200/30 mb-2">
          <div className="flex items-center text-lg mb-2">
            <span className="mr-3 w-9 h-9 bg-white rounded-full flex justify-center items-center font-semibold">
              2
            </span>
            <h2 className="font-medium">Organize the Data as a Template</h2>
          </div>
          <div className="pl-12">
            <p className="font-medium text-sm pb-6 border-b mb-4">
              Ensure all data entries are consistent in format.
            </p>
            <p className="font-semibold text-sm mb-2.5 text-activeNav-color/50">
              Correct format template
            </p>
            <div className="rounded-lg border bg-white px-4 py-3 text-sm mb-4">
              <p className="font-medium mb-1.5">
                House Number, Street Name, Locality, Post Index
              </p>
              <p className="font-normal text-activeNav-color/50">
                Note: separate by “,”
              </p>
            </div>
            <a
              href="/CSVTemplate.csv"
              download
              className="flex items-center rounded-md text-sm font-medium bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
            >
              <FileDown className="w-[18px] h-[18px] mr-1.5" />
              Download .CSV Template
            </a>
          </div>
        </div>
        <div className="py-4 px-6 rounded-2xl bg-slate-200/30 flex items-center text-lg mb-2">
          <span className="mr-3 w-9 h-9 bg-white rounded-full flex justify-center items-center font-semibold">
            3
          </span>
          <h2 className="font-medium">Save your .CSV File</h2>
        </div>
      </div>
      <div>
        <div className="mb-5 flex font-semibold items-center">
          <span className="mr-3 w-9 h-9 bg-activeNav-color text-white rounded-full flex justify-center items-center text-base">
            2
          </span>
          <h1 className="text-xl">Upload CSV file</h1>
        </div>
        <div
          className={`flex mb-5 flex-col justify-center items-center p-11 border-dashed border-2 rounded-2xl border-activeNav-color/30 hover:cursor-pointer hover:opacity-100 hover:border-activeNav-color/50 ${
            isDragActive ? "opacity-100" : "opacity-50"
          }`}
          {...getRootProps()}
        >
          <div className="h-28 w-28 bg-scoreCard-background rounded-full mb-4 flex justify-center items-center">
            <Paperclip className="h-12 w-12" />
          </div>
          <h1 className="mb-4 text-2xl font-semibold text-activeNav-color">
            Drag and Drop or Click to upload
          </h1>
          <p className="mb-8 font-medium text-lg text-activeNav-color/50">
            Make sure all data in your CSV file is correct.
            {/* Max. file size: 10KB. */}
          </p>
          <div>
            <Input id="csv" type="file" {...getInputProps()} />
          </div>
        </div>
        <p className="text-activeNav-color/50 text-sm italic font-medium text-center mb-2">
          Correct CSV data format is: House Number, Street Name, City, Post
          Index. Address example: 11, Woodlea, Leybourne, CR0 1PB
        </p>
        {files && <p className="mb-2">Selected files: {files.name}</p>}
        <Button
          onClick={onSubmit}
          type="submit"
          disabled={!files || isPending}
          className="w-full"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
