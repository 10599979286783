import { Statuses } from "@/api/scoring/types";

type Config = {
  [key: string]: "secondary" | "outline" | "destructive";
};

export const config: Config = {
  [Statuses.PENDING]: "secondary",
  [Statuses.CALCULATED]: "outline",
  [Statuses.FAILED]: "destructive",
};
