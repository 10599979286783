import type { Key } from "@/types/api-keys";
import type { ColumnDef } from "@tanstack/react-table";
import { DeleteKeyDialog } from "./delete-key-dialog";

export const columns: ColumnDef<Key>[] = [
  {
    accessorKey: "id",
    header: "id",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "token",
    header: "Token",
  },
  {
    accessorKey: "createdAt",
    header: "CreatedAt",
  },
  {
    id: "delete",
    cell: ({ row }) => (
      <DeleteKeyDialog id={row.original.id} isLocked={row.original.isLocked} />
    ),
  },
];
