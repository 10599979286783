import { Button } from "@/components/ui/button";
import { useNavigate } from "@tanstack/react-router";
import { Home, NotepadText } from "lucide-react";

function EmptyState() {
  const navigate = useNavigate();

  const goToAddress = () => {
    navigate({ to: "/addresses" });
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="h-28 w-28 bg-scoreCard-background rounded-full mb-4 flex justify-center items-center">
        <NotepadText className="h-12 w-12" />
      </div>
      <h1 className="mb-4 text-2xl font-semibold text-activeNav-color">
        Let’s start with uploading addresses
      </h1>
      <p className="mb-8 font-medium text-lg text-activeNav-color/50">
        Upload at least 10 addresses to calculate your mortgage portfolio
        profit.
      </p>
      <Button
        onClick={goToAddress}
        className="px-4 py-3  bg-activeNav-color text-white"
        variant="outline"
        type="submit"
      >
        <Home className="mr-2 h-4 w-4" />
        Go to Address
      </Button>
    </div>
  );
}

export default EmptyState;
