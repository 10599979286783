import { RATINGS_TABS } from "@/configs/explanation-charts";
import type {
  ArrayWithNumberAndStringProperties,
  NewValueExplanationCharts,
} from "@/types/portfolio-analysis";
import { formatNumberWithTwoDecimals } from "@/utils/format-number-with-two-decimals";
import type {
  Dashboard,
  NewDashboard,
  RecommendationsList,
  StringToNumberMap,
} from "./types";

const RECOMMENDATION_BAR = "recommendationBar";
const MARKET_VOLUME = "marketVolume";

const convertObjectToKeyValueArray = <T extends object>(object: T) =>
  Object.entries(object).map(([name, value]) => ({
    name,
    value,
  })) as Array<{ name: string; value: T[keyof T] }>;

const getRecommendationsList = (
  array: ArrayWithNumberAndStringProperties,
  recommendationsList: RecommendationsList[],
): ArrayWithNumberAndStringProperties =>
  array.map((item) => {
    const foundRecommendation = recommendationsList.find(
      (recommendation) => Number(recommendation.index) === Number(item.name),
    );
    return {
      name: foundRecommendation ? foundRecommendation.recommendation_short : "",
      value: item.value,
    };
  });

export const dashboard = (data: Dashboard): NewDashboard => {
  const certificateAgeChart = convertObjectToKeyValueArray(
    data.certificateAgeChart,
  );
  const ratingBar = convertObjectToKeyValueArray(data.ratingBar);

  const totalInvestmentProfitObject = Object.entries(
    data.valueExplanationCharts,
  ).reduce(
    (acc, arr) => {
      const {
        averageMarketPrice,
        paybackYears,
        mortgagePortfolioGrowth,
        dwellingsNumber,
        improvement,
        investments,
      } = arr[1];
      const rating: string = arr[0];

      if (RATINGS_TABS[rating]) {
        acc.averageMarketPrice += averageMarketPrice;
        acc.paybackYears = paybackYears;
        acc.mortgagePortfolioGrowth += mortgagePortfolioGrowth;
        acc.dwellingsNumber += dwellingsNumber;
        acc.improvement = improvement;
        acc.investments += investments;
      }

      return acc;
    },
    {
      improvement: "",
      dwellingsNumber: 0,
      investments: 0,
      paybackYears: 0,
      averageMarketPrice: 0,
      marketPriceGrowth: 0,
      mortgagePortfolioGrowth: 0,
    },
  );

  // TODO: calculation of Marker price growth in Total investment profit
  totalInvestmentProfitObject.marketPriceGrowth =
    totalInvestmentProfitObject.averageMarketPrice
      ? (totalInvestmentProfitObject.mortgagePortfolioGrowth /
          totalInvestmentProfitObject.averageMarketPrice -
          1) *
        100
      : 0;

  const totalInvestmentProfit = convertObjectToKeyValueArray(
    totalInvestmentProfitObject,
  );

  const valueExplanationCharts = convertObjectToKeyValueArray(
    data.valueExplanationCharts,
  ).reduce((obj, explanation) => {
    explanation.value = {
      improvement: explanation.value.improvement,
      dwellingsNumber: explanation.value.dwellingsNumber,
      investments: explanation.value.investments,
      paybackYears: explanation.value.paybackYears,
      averageMarketPrice: explanation.value.averageMarketPrice,
      marketPriceGrowth: explanation.value.marketPriceGrowth,
      mortgagePortfolioGrowth: explanation.value.mortgagePortfolioGrowth,
      marketVolume: explanation.value.marketVolume,
      recommendationBar: explanation.value.recommendationBar,
    };

    const explanationArr = convertObjectToKeyValueArray(explanation.value);

    let marketVolume: ArrayWithNumberAndStringProperties = [];
    let recommendationsBar: ArrayWithNumberAndStringProperties = [];

    for (const item of explanationArr) {
      if (item.name === MARKET_VOLUME) {
        const marketVolumeArr = convertObjectToKeyValueArray(
          item.value as StringToNumberMap,
        );

        marketVolume = getRecommendationsList(
          marketVolumeArr,
          data.recommendationsList,
        ).map((item) => ({
          ...item,
          value: Number(formatNumberWithTwoDecimals(item.value)),
        }));
      } else if (item.name === RECOMMENDATION_BAR) {
        const recommendationsBarArr = convertObjectToKeyValueArray(
          item.value as StringToNumberMap,
        );
        recommendationsBar = getRecommendationsList(
          recommendationsBarArr,
          data.recommendationsList,
        );
      }
    }

    const investmentProfit = explanationArr.filter(
      (item) => item.name !== RECOMMENDATION_BAR && item.name !== MARKET_VOLUME,
    );

    return {
      // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
      ...obj,
      [explanation.name]: {
        investmentProfit,
        marketVolume,
        recommendationsBar,
      },
    };
  }, {}) as NewValueExplanationCharts;

  return {
    ...data,
    certificateAgeChart,
    ratingBar,
    valueExplanationCharts,
    totalInvestmentProfit,
  };
};
