import { useGetKeys } from "@/api/api-keys/queries";
import { Table } from "@/components/table";
import { createFileRoute } from "@tanstack/react-router";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AddKeyDialog } from "./-components/add-key-dialog";
import { columns } from "./-components/columns";

export const Route = createFileRoute("/_authenticated/api-keys/")({
  component: ApiKeys,
});

function ApiKeys() {
  const { data } = useGetKeys();
  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="p-4 w-full">
      <div className="flex items-center gap-4 py-4">
        <AddKeyDialog />
      </div>
      {data && <Table columns={columns} table={table} />}
    </div>
  );
}
