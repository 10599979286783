import { useAuth, useUser } from "@clerk/clerk-react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    auth: undefined!,
    organization: undefined!,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function App() {
  const { isLoaded: isLoadedAuth, isSignedIn } = useAuth();
  const { isLoaded: isLoadedUser, user } = useUser();

  if (!isLoadedAuth || !isLoadedUser) {
    return;
  }

  const hasOrganization = user?.organizationMemberships
    ? user.organizationMemberships.length > 0
    : false;

  return (
    <RouterProvider
      router={router}
      defaultPreload="intent"
      context={{
        auth: { isSignedIn },
        organization: {
          hasOrganization,
        },
      }}
    />
  );
}

export default App;
