import { SignIn } from "@clerk/clerk-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/sign-in")({
  component: SignInComponent,
});

function SignInComponent() {
  return (
    <div className="flex items-center justify-center py-4">
      <SignIn path="/sign-in" routing="hash" signUpUrl="/sign-up" />
    </div>
  );
}
