import type { ArrayWithNumberAndStringProperties } from "@/types/portfolio-analysis";

const SOLAR_PANELS = "Solar photovoltaic panels, 2.5 kWp";

export function sixMaxValues(values: ArrayWithNumberAndStringProperties) {
  const copyOfValues: ArrayWithNumberAndStringProperties = JSON.parse(
    JSON.stringify(values),
  );

  return copyOfValues
    .sort((a, b) => {
      if (a.name === SOLAR_PANELS) {
        return -1;
      }
      if (b.name === SOLAR_PANELS) {
        return 1;
      }
      return b.value - a.value;
    })
    .slice(0, 6);
}
