import { useGetDashboard, useGetScores } from "@/api/scoring/queries";
import { Statuses } from "@/api/scoring/types";
import { createFileRoute } from "@tanstack/react-router";
import ChartsWithAnalysis from "./-components/charts-with-analysis";
import EmptyState from "./-components/empty-state";
import Kpi from "./-components/kpi";
import PortfolioGrowthOpportunities from "./-components/portfolio-growth-opportunities";
import TotalInvestmentProfit from "./-components/total-investment-profit";

export const Route = createFileRoute("/_authenticated/portfolio-analysis/")({
  component: PortfolioAnalysis,
});

function PortfolioAnalysis() {
  const { data } = useGetDashboard();
  const { data: score } = useGetScores();

  if (!data || !score) return;

  const calculatedLength = score.filter(
    (item) => item.status === Statuses.CALCULATED,
  ).length;

  return (
    <>
      <h1 className="text-2xl font-semibold text-activeNav-color w-full mx-6">
        Portfolio Insights
      </h1>
      <div className="w-full">
        {calculatedLength > 9 ? (
          <div className="py-4">
            <Kpi kpi={data.kpi} />
            <ChartsWithAnalysis
              certificateAgeChart={data.certificateAgeChart}
              ratingBar={data.ratingBar}
            />
            <PortfolioGrowthOpportunities data={data} />
            <TotalInvestmentProfit data={data.totalInvestmentProfit} />
          </div>
        ) : (
          <div className="min-h-[calc(100vh-238px)] flex justify-center items-center">
            <EmptyState />
          </div>
        )}
      </div>
    </>
  );
}
