import type { ArrayWithNumberAndStringProperties } from "@/types/portfolio-analysis";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type Props = {
  data: ArrayWithNumberAndStringProperties;
  barName: string;
};

// biome-ignore lint: <explanation>
const CustomLabel = (props: any) => {
  return (
    <g>
      <text x={props.viewBox.x} y={props.viewBox.y} dy={25} dx={5}>
        {props.name}
      </text>
    </g>
  );
};

function Vertical({ data, barName }: Props) {
  return (
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout="vertical" width={500}>
          <CartesianGrid vertical={false} strokeDasharray="10" />
          <XAxis type="number" axisLine={false} hide={true} />
          <YAxis dataKey="name" type="category" axisLine={false} hide={true} />
          <Tooltip
            itemStyle={{ color: "black" }}
            cursor={{ fill: "none" }}
            labelFormatter={() => ""}
          />
          <Bar
            dataKey="value"
            name={barName}
            fill="#E2E8F0"
            radius={[0, 10, 10, 0]}
            activeBar={<Rectangle fill="#E9FF99" />}
            label={<CustomLabel />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Vertical;
