import type { EPCData } from "@/api/scoring/types";
import { formatNumberWithTwoDecimals } from "./format-number-with-two-decimals";

export function calculatingValuesPropertyStatistics(epc: EPCData) {
  const consumptionM2 = formatNumberWithTwoDecimals(
    Number(epc.ENERGY_CONSUMPTION_CURRENT) / 12,
  );
  const monthlyConsumption = formatNumberWithTwoDecimals(
    (Number(epc.ENERGY_CONSUMPTION_CURRENT) * Number(epc.TOTAL_FLOOR_AREA)) /
      12,
  );
  const annualConsumption = formatNumberWithTwoDecimals(
    Number(epc.ENERGY_CONSUMPTION_CURRENT) * Number(epc.TOTAL_FLOOR_AREA),
  );
  const monthlyBill = formatNumberWithTwoDecimals(
    ((Number(epc.ENERGY_CONSUMPTION_CURRENT) * Number(epc.TOTAL_FLOOR_AREA)) /
      12) *
      0.118,
  );
  const annualBill = formatNumberWithTwoDecimals(
    Number(epc.ENERGY_CONSUMPTION_CURRENT) *
      Number(epc.TOTAL_FLOOR_AREA) *
      0.118,
  );

  return {
    consumptionM2,
    monthlyConsumption,
    annualConsumption,
    monthlyBill,
    annualBill,
  };
}
