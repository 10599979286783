import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { HomeHealthScoreType } from "@/types/detailed-score";
import Pie from "./pie";

const HomeHealthScore = ({ score }: HomeHealthScoreType) => {
  const percentage = (score / 200) * 100;

  return (
    <div className="p-6 rounded-lg border bg-scoreCard-background">
      <div className="flex justify-between mb-4 font-medium">
        <span className="text-lg">HomeHealth Score</span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="ghost"
                size="icon"
                className="rounded-xl border-black border w-5 h-5"
              >
                i
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                Home health score is a score that is calculated by the system
                for further work with home owners and encouraging them to
                improve the energy efficiency of their home
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex justify-between">
        <div className="flex items-end">
          <span className="text-5xl font-bold mr-2">{score}</span>
          <span className="font-semibold text-base">/200</span>
        </div>
        <Pie percentage={percentage} color="black" />
      </div>
    </div>
  );
};
export default HomeHealthScore;
