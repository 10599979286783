import type { InvestmentProfit } from "@/types/portfolio-analysis";
import { formatNumberWithTwoDecimals } from "@/utils/format-number-with-two-decimals";

const getValueWithSymbol = (item: InvestmentProfit) => {
  const fixedValue =
    typeof item.value === "number"
      ? formatNumberWithTwoDecimals(item.value)
      : item.value;

  switch (item.name) {
    case "investments":
    case "averageMarketPrice":
    case "mortgagePortfolioGrowth":
      return `£${fixedValue}`;
    case "paybackYears":
      return `${fixedValue} years`;
    case "marketPriceGrowth":
      return `${fixedValue}%`;
    default:
      return fixedValue;
  }
};

export default getValueWithSymbol;
