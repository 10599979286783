import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Protect, SignOutButton, useUser } from "@clerk/clerk-react";
import { Link, Outlet, useNavigate } from "@tanstack/react-router";
import { clsx } from "clsx";
import { Menu, Package2 } from "lucide-react";
import { NAV_ITEMS } from "./nav-items";

function PageLayout() {
  const navigate = useNavigate();
  const { user } = useUser();

  const organizationName = user?.organizationMemberships
    ? user?.organizationMemberships[0].organization.name
    : "Smartlayer";

  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-[72px] items-center gap-4 border-b bg-background px-4 md:px-10 z-[2]">
        <div className="hidden md:flex items-center lg:h-[60px]">
          <Link
            to="/addresses"
            className="flex items-center gap-2 text-lg font-semibold text-activeNav-color"
          >
            <Package2 className="h-6 w-6" />
            <span>{organizationName}</span>
          </Link>
        </div>
        <nav className="hidden px-2 divide-x flex-1 md:flex items-center justify-center text-sm font-medium">
          {NAV_ITEMS.map((item) => (
            <div className="px-5" key={item.to}>
              <Protect role={item.role}>
                <Link to={item.to}>
                  {({ isActive }) => {
                    return (
                      <div
                        className={clsx(
                          "flex items-center p-1 pr-3 gap-2 rounded-lg hover:text-primary",
                          isActive && "text-activeNav-color bg-slate-200/50",
                          !isActive && "text-activeNav-color/70 bg-none",
                        )}
                      >
                        <div
                          className={clsx(
                            "p-2 rounded-lg gap-2",
                            isActive && "bg-activeNav-background",
                            !isActive && "bg-white",
                          )}
                        >
                          {item.icon}
                        </div>
                        <span className="font-medium text-base">
                          {item.label}
                        </span>
                      </div>
                    );
                  }}
                </Link>
              </Protect>
            </div>
          ))}
        </nav>
        <div className="hidden md:flex items-center gap-3 rounded-lg text-activeNav-color/70 transition-all hover:text-primary">
          <SignOutButton signOutCallback={() => navigate({ to: "/sign-in" })} />
        </div>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="flex flex-col">
            <nav className="grid gap-2 text-lg font-medium">
              <Link
                to="/addresses"
                className="flex items-center gap-2 font-semibold"
              >
                <Package2 className="h-6 w-6" />
                <span className="">{organizationName}</span>
              </Link>
              {NAV_ITEMS.map((item) => (
                <Protect role={item.role} key={item.to}>
                  <Link
                    to={item.to}
                    className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-activeNav-color/70 hover:text-foreground"
                  >
                    {item.icon}
                    {item.label}
                  </Link>
                </Protect>
              ))}
            </nav>
            <div className="mt-auto mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-activeNav-color/70 hover:text-foreground">
              <SignOutButton
                signOutCallback={() => navigate({ to: "/sign-in" })}
              />
            </div>
          </SheetContent>
        </Sheet>
      </header>
      <main className="flex items-center flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-10 bg-secondaryBackground">
        <Outlet />
      </main>
    </div>
  );
}

export default PageLayout;
