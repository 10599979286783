import type { KPIData } from "@/types/portfolio-analysis";
import {
  BadgeDollarSign,
  Clock,
  MessageCircleMore,
  School,
  ThumbsUp,
} from "lucide-react";
import type { ReactElement } from "react";

type CardProps = {
  icon: ReactElement;
  name: string;
  value: string | number;
};

type Props = {
  kpi: KPIData;
};

const Card = ({ icon, name, value }: CardProps) => {
  return (
    <div className="p-6 flex flex-col justify-between text-3xl font-bold">
      <div className="flex flex-row items-center justify-between space-y-0 mb-8">
        <p className="text-base font-medium">{name}</p>
        {icon}
      </div>
      <div className="text-3xl font-bold">{value}</div>
    </div>
  );
};

const KPI = ({ kpi }: Props) => {
  return (
    <div className="grid grid-cols-5 rounded-xl	border bg-white divide-x mb-4">
      <div className="col-span-1">
        <Card
          icon={<School />}
          name="Total No of Properties"
          value={kpi.totalNumberOfAddresses}
        />
      </div>
      <div className="col-span-1">
        <Card
          icon={<ThumbsUp />}
          name="No of Properties with an EPC Rating"
          value={kpi.addressesWithRating}
        />
      </div>
      <div className="col-span-1">
        <Card
          icon={<Clock />}
          name="Outdated EPC Ratings"
          value={kpi.addressesWithOutdatedRating}
        />
      </div>
      <div className="col-span-1">
        <Card
          icon={<MessageCircleMore />}
          name="Engagement required"
          value={kpi.involvementInRatingUpgrade}
        />
      </div>
      <div className="col-span-1">
        <Card
          icon={<BadgeDollarSign />}
          name="Suitability for Financing"
          value={kpi.suitabilityOfFinancing}
        />
      </div>
    </div>
  );
};

export default KPI;
