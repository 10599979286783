import type { CircleType, PieType } from "@/types/detailed-score";

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ color, pct }: CircleType) => {
  const r = 24;
  const circ = 2 * Math.PI * r;
  const strokePct = pct ? ((100 - pct) * circ) / 100 : 0;

  return (
    <circle
      r={r}
      cx={85}
      cy={35}
      fill="transparent"
      stroke={strokePct !== circ ? color : ""}
      strokeWidth={"8px"}
      strokeDasharray={circ}
      strokeDashoffset={strokePct}
      strokeLinecap="round"
    />
  );
};

// const Text = ({ percentage }: { percentage: number }) => {
//   return (
//     <text
//       x="50%"
//       y="50%"
//       dominantBaseline="central"
//       textAnchor="middle"
//       fontSize={"1.5em"}
//     >
//       {percentage.toFixed(0)}%
//     </text>
//   );
// };

const Pie = ({ percentage, color }: PieType) => {
  const pct = cleanPercentage(percentage);

  return (
    <svg width={70} height={70} role="img" aria-label="Pie">
      <g transform={`rotate(-90 ${"60 60"})`}>
        <Circle color="white" />
        <Circle color={color} pct={pct} />
      </g>
    </svg>
  );
};

export default Pie;
