import { OrganizationProfile } from "@clerk/clerk-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/organization-profile/")({
  component: ProfileComponent,
});

function ProfileComponent() {
  return <OrganizationProfile path="/organization/profile" routing="hash" />;
}
