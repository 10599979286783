import { HorizontalBar } from "@/components/charts/Bars";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import type { ArrayWithNumberAndStringProperties } from "@/types/portfolio-analysis";

type Props = {
  certificateAgeChart: ArrayWithNumberAndStringProperties;
  ratingBar: ArrayWithNumberAndStringProperties;
};

const ChartsWithAnalysis = ({ certificateAgeChart, ratingBar }: Props) => {
  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-2 mb-10">
      <Card x-chunk="dashboard-01-chunk-1" className="rounded-xl">
        <CardHeader className="flex flex-row items-center justify-end space-y-0 pb-2" />
        <CardContent>
          <div className="text-lg font-semibold mb-20">
            Property by EPC Rating
          </div>
          <HorizontalBar data={ratingBar} label="rating" barName="number" />
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-1" className="rounded-xl">
        <CardHeader className="flex flex-row items-center justify-end space-y-0 pb-2" />
        <CardContent>
          <div className="text-lg font-semibold mb-20">
            Certificates by EPC age
          </div>
          <HorizontalBar
            data={certificateAgeChart}
            barName="number"
            label="years"
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default ChartsWithAnalysis;
