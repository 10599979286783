import { useGetScoreById } from "@/api/scoring/queries";
import { Statuses } from "@/api/scoring/types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { formatNumberWithTwoDecimals } from "@/utils/format-number-with-two-decimals";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import { ChevronDown, Files } from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EstimateFromEPC from "./-components/estimate-from-epc";
import HomeHealthScore from "./-components/home-health-score";
import PropertyStatistics from "./-components/property-statistics";

export const Route = createFileRoute("/_authenticated/detailed-score/$id")({
  component: DetailedScoreComponent,
});

function DetailedScoreComponent() {
  const params = Route.useParams();
  const { data } = useGetScoreById({ id: params.id });

  if (!data) {
    return;
  }

  if (data.status !== Statuses.CALCULATED) {
    return <Navigate to="/addresses" />;
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">{data.address}</h1>
        <div>
          <CopyToClipboard text={data.id}>
            <Button variant="outline" className="mr-4">
              <Files className="h-4 w-4 mr-2" />
              Copy Score ID
            </Button>
          </CopyToClipboard>
          <CopyToClipboard text={data.apiPlusLink}>
            <Button variant="outline">
              <Files className="h-4 w-4 mr-2" />
              Copy Link to API+
            </Button>
          </CopyToClipboard>
        </div>
      </div>
      <div className="grid grid-cols-4 mb-10">
        <div className="col-span-1 mr-5">
          <HomeHealthScore score={data.score} />
        </div>
        <div className="col-span-3">
          <EstimateFromEPC epc={data.epcData} />
        </div>
      </div>
      <div>
        <div className="mb-10">
          <h1 className="mb-5 text-2xl font-semibold">Property statistics</h1>
          <PropertyStatistics epc={data.epcData} />
        </div>
        <div>
          <h1 className="mb-5 text-2xl font-semibold">
            Retrofit Recommendations
          </h1>
        </div>
        {data?.recommendations && (
          <Accordion
            type="single"
            collapsible
            className="w-full bg-white border border-1 rounded-md"
          >
            {data.recommendations.map((item) => (
              <AccordionItem value={item.id} key={item.id} className="p-6">
                <AccordionTrigger className="font-medium text-2xl p-0 [&>svg:last-of-type]:hidden">
                  {item.recommendation_short}
                  <div className="text-lg flex items-center">
                    Details
                    <ChevronDown className="ml-3 h-4 w-4 shrink-0 transition-transform duration-200" />
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid grid-cols-2 mt-6">
                    <div className="col-span-1 col-end-3">
                      <p className="font-normal text-lg text-activeNav-color/70 mb-6">
                        {item.recommendation_detailed}
                      </p>
                      <div className="grid grid-cols-2">
                        <div>
                          <p className="mb-2 text-lg font-medium">
                            Estimated Saving p.a.
                          </p>
                          <p className="mb-2 text-2xl font-semibold">
                            £{formatNumberWithTwoDecimals(item.saving_per_year)}
                          </p>
                        </div>
                        <div>
                          <p className="mb-2 text-lg font-medium">
                            Investment amount
                          </p>
                          <p className="mb-2 text-2xl font-semibold">
                            £
                            {formatNumberWithTwoDecimals(
                              Number(item.indicative_cost),
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
}
